.main {
  ul[class],
  ol[class] {
    padding: 0;
    margin: 0 0 1.25rem 1.25rem;
  }

  ul[class] {
    list-style-type: disc;
  }

  ol[class] {
    list-style-type: decimal;
  }

  code * {
    font-family: monospace;
  }

  .main__control {
    display: flex;
    margin: 10px 0 20px 0;
    width: 100%;

    .buttonsGroup {
      display: flex;
      gap: 8px;
    }

    .checkButton {
      flex-shrink: 0;
    }

    .checkButtonContent {
      display: flex;
      align-items: center;
      color: #000;

      img {
        margin-right: 5px;
        width: 15px;
      }
    }

    .wideButtonContent {
      padding: 0 4px;
    }

    img.retryButtonIcon {
      margin-right: 8px;
    }

    .control__switch {
      margin: 0 30px;
      height: 40px;
      color: #036fe3;

      & > label {
        font-size: 13px;
      }
    }

    .score {
      flex: 1;
      margin-left: 8px;
      display: flex;
      align-items: center;
      gap: 10px;
      overflow: hidden;

      &.hidden {
        visibility: hidden;
      }

      .value {
        color: #333;
        flex-shrink: 0;
        font-size: 22px;
        line-height: 1;
        font-weight: 600;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .valueTop {
          display: flex;
          margin-top: 3px;
        }

        .valueContent {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 64px;

          & > p {
            margin: 0;
          }
        }

        .spinner {
          position: relative;
          width: 24px;
          height: 24px;
          box-sizing: border-box;
          border-radius: 50%;
          border: 3px solid #D6D6D6;
          border-top-color: #000;
          border-right-color: #000;
          animation: spin 1s var(--spinner-animation-function) infinite;
        }

        @keyframes spin {
          to {
            transform: rotate(360deg);
          }
        }

        .valueDescription {
          width: 131px;
          font-size: 10px;
          font-weight: 600;
          text-transform: uppercase;
          line-height: 14px;
          letter-spacing: 2px;
          white-space: pre-line;

          &.short {
            width: 63px;
          }
        }

        .progressBarRail {
          position: relative;
          height: 6px;
          border-radius: 32px;
          background-color: #D6D6D6;
        }

        .progressBarFill {
          position: absolute;
          left: 0;
          top: 0;
          height: 100%;
          width: 40px;
          background-color: #000;
          border-radius: 32px;
          transition: width 200ms ease-in-out;
        }

        &.bad {
          .progressBarFill {
            background: linear-gradient(180deg,rgb(255 174 151) 0,rgb(255 115 80) 100%);
          }
        }

        &.good {
          .progressBarFill {
            background: linear-gradient(8.05deg,rgb(36 203 106) 10.98%,rgb(120 237 170) 79.45%);
          }
        }

        &.perfect {
          .progressBarFill {
            background: linear-gradient(8.05deg,rgb(36 203 106) 10.98%,rgb(120 237 170) 79.45%);
          }
        }
      }

      .separator {
        width: 1px;
        height: 30px;
        margin: 0 2px;
        background: #E4E7ED;
      }

      .message {
        flex: 1;
        font-size: 14px;
        line-height: 20px;
        color: #828282;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: pre-line;

        @media screen and (max-width: 700px) {
          white-space: nowrap;
        }
      }

      .closeButtonIcon {
        flex: 0;
      }
    }
  }

  .main__changed {
    position: relative;
    width: calc(100% - 64px);
    padding: 12px 32px;
    margin: -6px 0 20px;
    border-radius: 8px;
    text-align: center;
    background: #F5F5F9;
    color: #4F4F4F;
    font-size: 14px;
    line-height: 20px;

    & > a {
      color: inherit;
    }

    .messageCloseButtonIcon {
      position: absolute;
      top: 5px;
      right: 7px;
      height: auto;
      width: auto;
    }
  }

  .main__content {
    width: 100%;
  }
}
