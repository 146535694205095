html {
  overflow: hidden;
}

html, body, div {
  margin: 0;
  padding: 0;
  border: 0;
  font-family: "Poppins", sans-serif !important;
  font-size: 100%;
  vertical-align: baseline;
  font-style: normal;
}
